<template>
  <div>
    <button class="btn btn-sm btn-blue" @click="$refs.createInvestmentActionModal.open()" v-if="canCreateInvestmentAction">Create an Investment Action {{ completeInvestmentButtonWithName }}</button>
    <datatable
      ref="table"
      :url="investmentResource.url"
      :ajax="true"
      :ajax-pagination="true"
      :columns="investmentResource.columns"
      :fillable="true"
      :loading="investmentResource.loading"
      :actions="actions"
      :limit="15"
      class="mb-8"
    />
    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="activatePndModal"
      @close="removeError"
    >
      <h4 class="text-2xl font-bold mb-12">Activate PND on Investment</h4>

      <div>
        <template v-if="pndError">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon mr-3">!</span>
            <span>{{ pndError }}</span>
          </div>
        </template>
        <investment-information-on-modal :selectedInvestment="selectedInvestment"/>
        <div class="text-center">
          <button
            @click.prevent="activatePnd"
            class="button bg-blue-500 text-white hover:bg-blue-600"
          >
            <template v-if="loading">Activating...</template>
            <template v-else>Activate</template>
          </button>
        </div>
      </div>
    </modal>
    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="deactivatePndModal"
      @close="removeError"
    >
      <h4 class="text-2xl font-bold mb-12">Deactivate PND on Investment</h4>

      <div>
        <template v-if="pndError">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon mr-3">!</span>
            <span>{{ pndError }}</span>
          </div>
        </template>
        <investment-information-on-modal :selectedInvestment="selectedInvestment"/>
        <div class="text-center">
          <button
            @click.prevent="deactivatePnd"
            class="button bg-blue-500 text-white hover:bg-blue-600"
          >
            <template v-if="loading">Deactivating...</template>
            <template v-else>Deactivate</template>
          </button>
        </div>
      </div>
    </modal>
    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="successModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">Success!</div>
      <div class="text-xs mb-10">
        {{ successMessage }}
      </div>
    </modal>
    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-8"
      ref="createInvestmentActionModal"
    >
      <form
        @submit.prevent="uploadInvestmentCreationAction"
        class="flex flex-col flex-grow"
      >
        <h4 class="text-xl font-bold mb-6">Create Investment Action</h4>
        <template v-if=getFormError(investmentActionCreationForm)>
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon ">!</span>
          <!-- getFormError returns a pretty ambiguous error, so returning the like form expectation error that could occur -->
          <span>Make sure investment name is unique, and amount is above the min investment amount</span>
        </div>
      </template>
        <form-group
          v-model="investmentActionCreationForm.data.investment_name.value"
          :form="investmentActionCreationForm"
          name="investment_name"
        >
          Investment Name
        </form-group>
        <form-group
          v-model="investmentActionCreationForm.data.amount.value"
          :form="investmentActionCreationForm"
          name="amount"
        >
          Amount
        </form-group>
        <custom-select
            :options="tenuresList"
            class="border border-blue-500 rounded-sm pl-3 py-2 pr-3 mr-3 mb-3 w-full"
            v-model="investmentActionCreationForm.data.tenure_id.value"
            placeholder="Please select a tenure"
        />
        <button
            type="submit"
            class="btn btn-blue btn-md mt-16 w-full"
            :disabled="investmentActionCreationForm.loading"
          >
          <sm-loader-white v-if="investmentActionCreationForm.loading" />
          <span v-else>Create Action</span>
        </button>
      </form>
    </modal>
    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-8"
      ref="extendInvestmentActionModal"
    >
      <form
        @submit.prevent="uploadInvestmentExtensionAction"
        class="flex flex-col flex-grow"
      >
        <h4 class="text-xl font-bold mb-6">Extend Investment Action</h4>
        <template v-if=getFormError(investmentActionExtensionForm)>
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon ">!</span>
            <span>{{this.getFormError(investmentActionExtensionForm)}}</span>
          </div>
        </template>

        <investment-information-on-modal :selectedInvestment="selectedInvestment"/>
        <custom-select
            :options="tenuresList"
            class="border border-blue-500 rounded-sm pl-3 py-2 pr-3 mr-3 mb-3 w-full"
            v-model="investmentActionExtensionForm.data.tenure_id.value"
            placeholder="Please select a tenure"
        />
        <button
            type="submit"
            class="btn btn-blue btn-md mt-16 w-full"
            :disabled="investmentActionExtensionForm.loading"
          >
          <sm-loader-white v-if="investmentActionExtensionForm.loading" />
          <span v-else>Extend Investment Action</span>
        </button>
      </form>
    </modal>
    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-8"
      ref="rolloverInvestmentActionModal"
    >
      <form
        @submit.prevent="uploadInvestmentRolloverAction"
        class="flex flex-col flex-grow"
      >
        <h4 class="text-xl font-bold mb-6">Rollover Investment Action</h4>
        <template v-if=getFormError(investmentActionRolloverForm)>
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon ">!</span>
            <span>{{this.getFormError(investmentActionRolloverForm)}}</span>
          </div>
        </template>
        <investment-information-on-modal :selectedInvestment="selectedInvestment"/>
        <span>Reinvest All</span>
        <custom-select
            :options="rolloverOptions"
            class="border border-blue-500 rounded-sm pl-3 py-2 pr-3 mr-3 mb-3 w-full"
            v-model="investmentActionRolloverForm.data.reinvest_all.value"
            name="reinvest_all"
        />
        <button
            type="submit"
            class="btn btn-blue btn-md mt-16 w-full"
            :disabled="investmentActionRolloverForm.loading"
          >
          <sm-loader-white v-if="investmentActionRolloverForm.loading" />
          <span v-else>Rollover Investment Action</span>
        </button>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  props: {
    staff: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    investmentInformationOnModal: () => import('../components/InvestmentInformationOnModal')
  },
  data() {
    return {
      investmentActionCreationForm: this.$options.basicForm([
        { name: 'investment_name', rules: 'required' },
        { name: 'tenure_id', rules: 'required'},
        { name: 'amount', rules: 'required'}
      ]),
      investmentActionExtensionForm: this.$options.basicForm([
        { name: 'tenure_id', rules: 'required'},
      ]),
      investmentActionRolloverForm: this.$options.basicForm([
        // The rules is an ingenious way of specifying a bool
        { name: 'reinvest_all', rules: 'required|number|min:0|max:1', value: '0'},
      ]),
      rolloverOptions: [{title: 'Yes', value: '1'}, {title: 'No', value: '0'}],
      tenures: [],
      selectedInvestment: null,
      pndError: null,
      liquidateInvestmentError: null,
      loading: false,
      successMessage: '',
      investmentResource: this.$options.resource([], {
        url: `${this.$baseurl}/invest/admin/personal/investment/${this.staff?.id}`,
        columns: [
          {
            name: 'id',
            th: 'Investment Id',
          },
          {
            name: 'name',
            th: 'Name',
          },
          {
            name: 'amount',
            th: 'Amount',
            render: (investment) =>
              `${this.$options.filters.formatAmount(investment?.amount)}`,
          },
          {
            name: 'earnings',
            th: 'Earnings',
            render: (investment) =>
              `${this.$options.filters.formatAmount(investment?.earnings)}`,
          },
          {
            name: 'pnd',
            th: 'PND',
            render: (investment, pnd) => this.pndStatusCheck(pnd),
          },
          {
            name: 'days',
            th: 'tenure',
            render: (investment) => `${investment?.days} Days`,
          },
          {
            name: 'status',
            th: 'Status',
            render: (investment, status) => this.investmentStatusCheck(status),
          },
          {
            name: 'created_at',
            th: 'Date',
            render: (investment, date) =>
              this.$moment(date).format('MMM Do YYYY'),
          },
        ],
      }),
      actions: [
        this.canDo('credpal:can_activate_invest_pnd')
          ? {
              text: 'activate pnd',
              class:
                'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
              action: this.openActivatePndModal,
              disabled: (investment) => investment.pnd === 1,
            }
          : '',
        this.canDo('credpal:can_deactivate_invest_pnd')
          ? {
              text: 'deactivate pnd',
              class:
                'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
              action: this.openDeactivatePndModal,
              disabled: (investment) => investment.pnd === 0,
            }
          : '',
        {
          text: 'Extend Investment Action',
          class:
            'bg-green-600 text-white rounded-sm px-4 py-2 hover:bg-green-500',
          action: this.openExtendInvestmentActionModal,
          show: (investment) => this.canCreateInvestmentAction && investment.status === 'running',
        },
        {
          text: 'Rollover Investment Action',
          class:
            'bg-orange-600 text-white rounded-sm px-4 py-2 hover:bg-orange-500',
          action: this.openRolloverInvestmentActionModal,
          show: (investment) => this.canCreateInvestmentAction && investment.status === 'closed',
        },
        {
          text: 'Liquidate Investment Action',
          class:
            'bg-red-600 text-white rounded-sm px-4 py-2 hover:bg-red-500',
          action: this.investmentLiquidationAction,
          show: (investment) => this.canCreateInvestmentAction && investment.status === 'running',
        },
      ],
    };
  },
  computed: {
    canViewInvest() {
      return this.canDo('credpal:can_view_invest');
    },
    canCreateInvestmentAction(){
      return this.canDo('credpal:can_create_investment_action');
    },
    completeInvestmentButtonWithName() {
      return this?.staff?.name ? `for ${this.staff.name}` : null;
    },
    tenuresList() {
      const tenuresList = [];
      if (Array.isArray(this.tenures)) {
        this.tenures.forEach(tenure => {
          tenuresList.push({
            title: tenure.days + ' days',
            value: tenure.id
          });
        });
      }
      return tenuresList;
    }
  },
  beforeMount() {
    this.showActions();
    this.fetchTenuresList()
  },
  methods: {
    pndStatusCheck(pnd) {
      switch (pnd) {
        case 1:
          return `<div class="rounded-sm border border-red-500 text-red-500 bg-red-100 text-xs px-2 py-1 inline-block">
                      active
                    </div>`;
        case 0:
        default:
          return `<div class="rounded-sm border border-blue-500 text-blue-500 bg-blue-100 text-xs px-2 py-1 inline-block">
                      inactive
                    </div>`;
      }
    },
    investmentStatusCheck(status) {
      switch (status) {
        case 'closed':
          return `<div class="rounded-sm border border-blue-500 text-blue-500 bg-blue-100 text-xs px-2 py-1 inline-block">
                      ${status}
                    </div>`;
        case 'liquidated':
          return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-2 py-1 inline-block">
                      ${status}
                    </div>`;
        case 'running':
        default:
          return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-2 py-1 inline-block">
                      ${status}
                    </div>`;
      }
    },
    showActions() {
      if (!this.canViewInvest) {
        this.actions = [];
        return;
      }
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    openActivatePndModal(investment) {
      this.selectedInvestment = investment;
      this.$refs.activatePndModal.open();
    },
    openDeactivatePndModal(investment) {
      this.selectedInvestment = investment;
      this.$refs.deactivatePndModal.open();
    },
    openExtendInvestmentActionModal(investment) {
      this.selectedInvestment = investment;
      this.$refs.extendInvestmentActionModal.open();
    },
    openRolloverInvestmentActionModal(investment) {
      this.selectedInvestment = investment;
      this.$refs.rolloverInvestmentActionModal.open();
    },
    investmentLiquidationAction(investment) {
      this.selectedInvestment = investment;
      this.uploadInvestmentLiquidationAction();
    },
    removeError() {
      this.pndError = '';
    },
    async activatePnd() {
      this.loading = true;
      this.pndStatusUpdateLoading = true;
      this.sendRequest('admin.invest.activatePndOnInvestment', this.selectedInvestment?.id, {
        headers: this.headers,

        success: () => {
          this.reloadTable();
          this.$refs.activatePndModal.close();
          this.selectedInvestment = null;
          this.successMessage = 'PND Activation Successful';
          this.$refs.successModal.open();
        },
        error: () => {
          this.pndError = 'PND Activation Failed';
        },
      });
      this.loading = false;
    },
    async deactivatePnd() {
      this.loading = true;
      this.pndStatusUpdateLoading = true;
      await this.sendRequest('admin.invest.deactivatePndOnInvestment', this.selectedInvestment?.id, {
        headers: this.headers,

        success: () => {
          this.reloadTable();
          this.$refs.deactivatePndModal.close();
          this.selectedInvestment = null;
          this.successMessage = 'PND Deactivation Successful';
          this.$refs.successModal.open();
        },
        error: () => {
          this.pndError = 'PND Deactivation Failed';
        },
      });
      this.loading = false;
    },
    fetchTenuresList() {
      this.sendRequest('personal.invest.tenures-list',
      {
        success: (response) => {
          this.tenures = response.data.data;
        },
        error: (error) => {
          this.$error({
            title: 'Investment Creation/Extension may not work as expected',
            body: error?.response?.data,
            button: 'Okay'
          });
        }
      });
    },
    uploadInvestmentCreationAction() {
      if (!this.validateForm(this.investmentActionCreationForm)) return;
      this.investmentActionCreationForm.loading = true;
      this.sendRequest('admin.invest.createInvestmentAction', {
          data: {
            ...this.getFormData(this.investmentActionCreationForm),
            user: {
              id: this?.staff?.id,
              name: this?.staff?.name + ' ' + this?.staff?.last_name,
              email: this?.staff?.email,
            },
            action_type: 'investment_creation',
            wallet_id: this?.staff?.cash_wallet?.wallet_id,
          },
          success: () => {
            this.investmentActionCreationForm.loading = false;
            this.$success({
              title: 'Operation Successful',
              body: 'Investment Create Action created successfully',
              button: 'Okay',
            });
            this.investmentActionCreationForm.loading = false;
            this.$emit('success');
            this.$refs.createInvestmentActionModal.close();
          },
          error: (error) => {
              this.investmentActionCreationForm.loading = false;
              this.investmentActionCreationForm.error = error
          }
        }
      )
    },
    uploadInvestmentExtensionAction() {
      if (!this.validateForm(this.investmentActionExtensionForm)) return;
      const tenureDays = this.tenures.find(tenure => tenure.id === this.investmentActionExtensionForm.data.tenure_id.value).days;

      if (tenureDays - this.selectedInvestment.days < 30) {
        this.$error({
          title: 'Action Creation Error',
          body: 'By configuration, Extending Tenure has to be at least 30 days greater than original tenure',
          button: 'Okay'
        });
        return ;
      }

      this.investmentActionExtensionForm.loading = true;
      this.sendRequest('admin.invest.createInvestmentAction', {
          data: {
            ...this.getFormData(this.investmentActionExtensionForm),
            investment_id: this?.selectedInvestment?.id,
            user: {
              id: this?.selectedInvestment?.user_id,
              name: this?.selectedInvestment?.user_name,
              email: this?.staff?.email,
            },
            action_type: 'investment_extension',
          },
          success: () => {
            this.investmentActionExtensionForm.loading = false;
            this.$success({
              title: 'Operation Successful',
              body: 'Investment Extend Action created successfully',
              button: 'Okay',
            });
  
            this.investmentActionExtensionForm.loading = false;
            this.$emit('success');
            this.$refs.extendInvestmentActionModal.close();
          },
          error: (error) => {
            this.investmentActionExtensionForm.loading = false;
            this.investmentActionExtensionForm.error = error
          }
        }
      )
    },
    uploadInvestmentRolloverAction() {
      if (!this.validateForm(this.investmentActionRolloverForm)) return;
      this.investmentActionRolloverForm.loading = true;
      this.sendRequest('admin.invest.createInvestmentAction', {
          data: {
            ...this.getFormData(this.investmentActionRolloverForm),
            investment_id: this?.selectedInvestment?.id,
            user: {
              id: this?.selectedInvestment?.user_id,
              name: this?.selectedInvestment?.user_name,
              email: this?.staff?.email,
            },
            action_type: 'investment_rollover',
          },
          success: () => {
            this.investmentActionRolloverForm.loading = false;
            this.$success({
              title: 'Operation Successful',
              body: 'Investment Rollover Action created successfully',
              button: 'Okay',
            });
  
            this.$emit('success');
            this.$refs.rolloverInvestmentActionModal.close();
          },
          error: (error) => {
            this.investmentActionRolloverForm.loading = false;
            this.investmentActionRolloverForm.error = error
          }
        }
      )
    },
    uploadInvestmentLiquidationAction() {
      this.sendRequest('admin.invest.createInvestmentAction', {
          data: {
            investment_id: this?.selectedInvestment?.id,
            user: {
              id: this?.selectedInvestment?.user_id,
              name: this?.selectedInvestment?.user_name,
              email: this?.staff?.email,
            },
            action_type: 'investment_liquidation',
          },
          success: () => {
            this.$success({
              title: 'Operation Successful',
              body: 'Investment Liquidate Action created successfully',
              button: 'Okay',
            });
            this.$emit('success');
          },
          error: (error) => {
            console.log(error);
            this.$error({
              title: 'Operation Failed',
              body: 'Investment Liquidate Action failed',
              button: 'Okay',
            });
          }
        }
      )
    },
  },
};
</script>
